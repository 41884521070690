import React from 'react';
import { useRef } from 'react';
import Header from '../components/Header';
import Home from '../components/Home';
import LiquidityLocker from '../components/LiquidityLocker';
import TokenVesting from '../components/TokenVesting';

function Landing() {
	const homeRef = useRef(null);
	const liquidityRef = useRef(null);
	const tokenRef = useRef(null);
	const homeScroll = () =>
		homeRef.current.scrollIntoView({
			behavior: 'smooth',
			inline: 'nearest',
		});
	const liquidityScroll = () =>
		liquidityRef.current.scrollIntoView({
			behavior: 'smooth',
			inline: 'nearest',
		});
	const tokenScroll = () =>
		tokenRef.current.scrollIntoView({
			behavior: 'smooth',
			inline: 'nearest',
		});
	return (
		<div>
			<Header
				homeScroll={homeScroll}
				liquidityScroll={liquidityScroll}
				tokenScroll={tokenScroll}
			/>
			<div ref={homeRef}>
				<Home />
			</div>
			<div ref={liquidityRef}>
				{' '}
				<LiquidityLocker />
			</div>
			{/* <div ref={tokenRef}>
				{' '}
				<TokenVesting />
			</div> */}
		</div>
	);
}

export default Landing;
