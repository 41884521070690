import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, ButtonGroup } from 'reactstrap';
import { connectWallet } from '../utils/web3-helpers';
import AllLock from './LiquidityLocker/AllLock';
import LpLock from './LiquidityLocker/LpLock';
import MyLock from './LiquidityLocker/MyLock';

function LiquidityLocker() {
	const [activeSection, setActiveSection] = useState('lp');

	const { walletAddress } = useSelector(state => state?.wallet);

	const connectHandler = async () => {
		await connectWallet();
	};

	return (
		<Container className='liquidity text-center'>
			<h2 className='liquidity__heading fs--40 fw--700'>
				LIQUIDITY LOCKER
			</h2>
			<span className='liquidity__text'>
				Lock liquidity pool tokens. Supports Ethereum (Uniswap V2),
				Polygon(Quickswap), BSC(PancakeSwap)
			</span>
			<br />
			{walletAddress ? (
				<Button className='btn btn-primary mt-4'>
					{walletAddress?.replace(walletAddress?.slice(4, 38), '***')}
				</Button>
			) : (
				<Button
					className='btn btn-primary mt-4'
					onClick={connectHandler}
				>
					Connect Wallet
				</Button>
			)}
			{/* <div className='mt-4'>
				<span className='liquidity__text'>
					Could not Connect. Please check your Wallet network.{' '}
				</span>
			</div>
			<div className='mt-5'>
				<ButtonGroup>
					<Button
						className={`btn ${
							activeTab == 'ethereum' ? 'btn-primary' : 'btn-alt'
						}`}
						onClick={() => setActiveTab('ethereum')}
					>
						Ethereum
					</Button>
					<Button
						className={`btn ${
							activeTab == 'polygon' ? 'btn-primary' : 'btn-alt'
						}`}
						onClick={() => setActiveTab('polygon')}
					>
						Polygon
					</Button>
					<Button
						className={`btn ${
							activeTab == 'bsc' ? 'btn-primary' : 'btn-alt'
						}`}
						onClick={() => setActiveTab('bsc')}
					>
						BSC
					</Button>
				</ButtonGroup>
			</div>
			<div className='mt-7 d-flex justify-content-start align-items-center'>
				<div
					className={`fs--28 fw--600 ${
						activeSection == 'lp'
							? 'liquidity__active-tab'
							: 'liquidity__simple-tab'
					}`}
					onClick={() => setActiveSection('lp')}
				>
					New LP Lock
				</div>
				<div
					className={`fs--28 fw--600 ${
						activeSection == 'my lock'
							? 'liquidity__active-tab'
							: 'liquidity__simple-tab'
					}`}
					onClick={() => setActiveSection('my lock')}
				>
					My Locks
				</div>
				<div
					className={`fs--28 fw--600 ${
						activeSection == 'all lock'
							? 'liquidity__active-tab'
							: 'liquidity__simple-tab'
					}`}
					onClick={() => setActiveSection('all lock')}
				>
					All locks
				</div>
			</div>
			<div className='mt-5'>
				{activeSection == 'lp' && <LpLock />}
				{activeSection == 'my lock' && <MyLock />}
				{activeSection == 'all lock' && <AllLock />}
			</div>
		</Container>
	);
			</div> */}
			<div className='mt-5'>
				<ButtonGroup>
					<Button className='btn-primary'>DOGE</Button>
				</ButtonGroup>
			</div>
			<div className='mt-7 d-flex justify-content-start align-items-center'>
				<div
					className={`${
						activeSection == 'lp'
							? 'liquidity__active-tab'
							: 'liquidity__simple-tab'
					}`}
					onClick={() => setActiveSection('lp')}
				>
					New LP Lock
				</div>
				<div
					className={`${
						activeSection == 'my lock'
							? 'liquidity__active-tab'
							: 'liquidity__simple-tab'
					}`}
					onClick={() => setActiveSection('my lock')}
				>
					My Locks
				</div>
				{/* <div
					className={`${
						activeSection == 'all lock'
							? 'liquidity__active-tab'
							: 'liquidity__simple-tab'
					}`}
					onClick={() => setActiveSection('all lock')}
				>
					All locks
				</div> */}
			</div>
			<div className='mt-5'>
				{activeSection == 'lp' && <LpLock />}
				{activeSection == 'my lock' && <MyLock />}
				{activeSection == 'all lock' && <AllLock />}
			</div>
		</Container>
	);
}

export default LiquidityLocker;
