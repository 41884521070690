import React, { useState } from 'react';
import {
	Container,
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';
import Logo from '../assets/img/logo.png';
function Header({ homeScroll, liquidityScroll, tokenScroll }) {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	return (
		<Navbar expand='md' className='custom-navbar'>
			<Container fluid className='px-2 px-md-4'>
				<NavbarBrand
					href='/'
					className='d-flex align-items-center fs--36 fw--700 custom-navbar__brand'
				>
					<img src={Logo} alt='logo' className='img-fluid mr-3' />
					<span className='d-none d-md-block'>Dogelock</span>
				</NavbarBrand>
				<NavbarToggler onClick={toggle}>
					<i className='fa fa-bars fa-2x'></i>
				</NavbarToggler>
				<Collapse isOpen={isOpen} navbar>
					<Nav className='ml-auto text-center' navbar>
						<NavItem className='fs--18 fw--500 custom-navbar__menu'>
							<NavLink onClick={homeScroll} className='pointer'>
								Home
							</NavLink>
						</NavItem>
						<NavItem className='fs--18 fw--500 custom-navbar__menu'>
							<NavLink
								onClick={liquidityScroll}
								className='pointer'
							>
								Liquidity Locker
							</NavLink>
						</NavItem>
						{/* <NavItem className='fs--18 fw--500 custom-navbar__menu'>
							<NavLink onClick={tokenScroll} className='pointer'>
								Token Vesting
							</NavLink>
						</NavItem> */}
					</Nav>
				</Collapse>
			</Container>
		</Navbar>
	);
}

export default Header;
