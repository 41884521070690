import React from 'react';
import { Container } from 'reactstrap';
import Reward from '../../assets/img/reward.png';
function AllLock() {
	return (
		<Container className='all-lock text-left mb-5'>
			{new Array(4).fill(1).map((item, idx) => {
				return (
					<div
						key={idx}
						className='d-flex align-items-center all-lock__reward'
					>
						<img src={Reward} alt='reward' className='mr-3' />
						<div>
							<h2 className='fs--28 fw--500 all-lock__reward-title'>
								FTC/ WETH
							</h2>
							<span className='fs--20 fw--400 all-lock__reward-subtitle'>
								Unlocks 8 months ago
							</span>
						</div>
					</div>
				);
			})}
		</Container>
	);
}

export default AllLock;
