export const configEnv = {
	// CONTRACT_ADDRESS: '0xB9be7b9D6dfA2a96A6A25E8cc730A11C013fC765',
	CONTRACT_ADDRESS: '0xFbec2f0819909E8B750d62BE06Cb7cB437b81E91',
	// DOGE_CHAINID: 80001,
	DOGE_CHAINID:2000, // for mainnet
	// DOGE_RPC: 'https://rpc.ankr.com/polygon_mumbai', //For testnet
	DOGE_RPC:"https://rpc03-sg.dogechain.dog", //For mainnet
	DOGE_CONTRACT_ADDRESS_URL:
		'https://explorer.dogechain.dog/address/0xFbec2f0819909E8B750d62BE06Cb7cB437b81E91/contracts', //Changed for mainnet,
	DOGE_NETWORK_ID: 'doge',
	DOGE_NETWORK_NAME: 'Doge Mainnet',
};