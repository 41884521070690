import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
function Home() {
	return (
		<Container fluid className='px-4 px-md-5 home'>
			<Row>
				<Col md='7'>
					<h4 className='fs--48 fw--700 home__heading '>
						Smart Liquidity Locker and Token Vesting For Ethereum,
						Polygon and BSC
					</h4>
					<Button className='btn-primary btn mt-4 '>
						Get Started
					</Button>
				</Col>
			</Row>
		</Container>
	);
}

export default Home;
