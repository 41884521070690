import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Button, Container, Input, Label, Spinner } from 'reactstrap';
import { configEnv } from '../../utils/configEnv';
import {
	approveToken,
	getBalanceOfToken,
	lockTokenWithDoge,
	lockTokenWithToken,
} from '../../utils/web3-helpers';
import { toast } from 'react-toastify';

function LpLock () {
	const { walletAddress, tokenBalanceInEth } = useSelector(
		state => state?.wallet
	);

	const [tokenAddress, setTokenAddress] = useState('');
	const [rangeValue, setRangeValue] = useState(0);
	const [tokenValue, setTokenValue] = useState(0);

	const [dateRange, setDateRange] = useState('');
	const [timeValue, setTimeValue] = useState('');
	const [dateRangeValue, setDateRangeValue] = useState(0);
	const [dateValue, setDateValue] = useState(0);
	const [isByToken, setIsByToken] = useState(false);
	const [approveLoader, setApproveLoader] = useState(false);
	const [lockLoader, setLockLoader] = useState(false);

	const maxDate = 157680000;

	const approveAmountHandler = async () => {
		setApproveLoader(true);
		try {
			const res = await approveToken(
				tokenAddress,
				tokenValue,
				configEnv?.CONTRACT_ADDRESS
			);
			setApproveLoader(false);
		} catch (err) {
			console.log(err);
			setApproveLoader(false);
			toast.warn(err?.message);
			throw new Error(err?.message);
		}
	};

	const lockAmountHandler = async () => {
		setLockLoader(true);
		try {
			if (isByToken) {
				debugger
				const res = await lockTokenWithToken(
					tokenAddress,
					tokenValue?.toString(),
					dateValue
				);
				setLockLoader(false);
				console.log(res);
			} else {
				const res = await lockTokenWithDoge(
					tokenAddress,
					tokenValue,
					dateValue
				);
				setLockLoader(false);
				console.log(res);
			}
		} catch (err) {
			console.log(err);
			setLockLoader(false);
			toast.warn(err?.message);
			throw new Error(err?.message);
		}
	};

	return (
		<Container className='text-left mb-5'>
			<div className='lplock'>
				<h2 className='fs--24 fw--700 lplock__title'>
					LP Pair Address
				</h2>
				<div className='mt-4 d-block d-md-flex align-items-center'>
					<Input
						type='text'
						placeholder='0x42981d0bfbAf196529376EE702f2a9Eb9092fcB5'
						className='lplock__input ml-0 ml-md-4'
						value={tokenAddress}
						disabled={!walletAddress}
						onChange={e => {
							setTokenAddress(e?.target.value);
						}}
						onBlur={() => {
							getBalanceOfToken(tokenAddress);
						}}
					/>
					<br />
					<span className='lplock__address fs--20 fw--300 ml-auto'>
						{!walletAddress && (
							<>
								Please Connect{' '}
								<br className='d-none d-md-block' /> Wallet to
								get started
							</>
						)}
					</span>
				</div>
			</div>
			<div className='lplock mt-5'>
				<h2 className='fs--24 fw--700 lplock__title'>LP Pair Amount</h2>
				<div className='mt-3 d-flex align-items-center'>
					<div className='lplock__address fs--20 fw--300'>
						{tokenValue?.toFixed(4)}
					</div>
					<div className='lplock__address fs--20 fw--300 ml-auto text-center'>
						<span>Balance</span>
						<br />
						<span>{tokenBalanceInEth || 0}</span>
					</div>
				</div>
				<div className='mt-5 px-2'>
					<Input
						type='range'
						value={rangeValue}
						disabled={!walletAddress}
						onChange={e => {
							setRangeValue(e?.target.value);
							const amount =
								(e?.target?.value / 100) *
								parseFloat(tokenBalanceInEth);
							setTokenValue(amount);
						}}
					/>
					<div className='d-flex mt-2'>
						<span className='ml-auto fs--36 fw--700 lplock__percentage'>
							{rangeValue || 0}%
						</span>
					</div>
					<div className='d-block d-md-flex mt-5 align-items-center justify-content-between px-5'>
						<Badge
							className='lplock__badge mr-2 mt-2'
							onClick={() => {
								if (walletAddress) {
									setRangeValue(25);
									const amount =
										(25 / 100) *
										parseFloat(tokenBalanceInEth);
									setTokenValue(amount);
								}
							}}
						>
							25%{' '}
						</Badge>
						<Badge
							className='lplock__badge mr-2 mt-2'
							onClick={() => {
								if (walletAddress) {
									setRangeValue(50);
									const amount =
										(50 / 100) *
										parseFloat(tokenBalanceInEth);
									setTokenValue(amount);
								}
							}}
						>
							50%{' '}
						</Badge>
						<Badge
							className='lplock__badge mr-2 mt-2'
							onClick={() => {
								if (walletAddress) {
									setRangeValue(75);
									const amount =
										(75 / 100) *
										parseFloat(tokenBalanceInEth);
									setTokenValue(amount);
								}
							}}
						>
							75%{' '}
						</Badge>
						<Badge
							className='lplock__badge mr-2 mt-2'
							onClick={() => {
								if (walletAddress) {
									setRangeValue(100);
									const amount =
										(100 / 100) *
										parseFloat(tokenBalanceInEth);
									setTokenValue(amount);
								}
							}}
						>
							Max{' '}
						</Badge>
					</div>
				</div>
			</div>
			<div className='lplock mt-5'>
				<h2 className='fs--24 fw--700 lplock__title'>Lock Period</h2>
				<div className='mt-3 d-flex align-items-center'>
					<div className='lplock__address fs--20 fw--300'>
						{new Date(Date.now() + dateValue * 1000).toDateString()}
					</div>
					<div className='lplock__address fs--20 fw--300 ml-auto text-center'>
						<span>
							Will lock for{' '}
							{moment(
								new Date(Date.now() + dateValue * 1000)
							).from(moment(new Date()))}
						</span>
					</div>
				</div>
				<div className='mt-5 px-2'>
					<div className='d-flex'>
						<div className='mr-3'>
							<Label>Date</Label>
							<Input
								type='date'
								value={dateRange}
								min={moment().format("YYYY-MM-DD")}
								disabled={!walletAddress}
								onChange={e => {
									setDateRange(e.target.value);

									const timestamp = new Date(e?.target?.value).getTime();
									const timePeriod = Math.floor((timestamp - Date.now()) / 1000);

									if (timePeriod >= maxDate) {
										setDateRangeValue(100);
									} else {
										const range = (timePeriod / maxDate) * 100;
										setDateRangeValue(range);
									}

									if (timeValue) {
										let selectedDateTime = (moment(e.target.value).set({ h: timeValue.split(':')[0], m: timeValue.split(':')[1] }).valueOf() - moment().valueOf()) / 1000;
										setDateValue(selectedDateTime);
									} else {
										let selectedDateTime = (moment(e.target.value).valueOf() - moment().valueOf()) / 1000;
										setDateValue(selectedDateTime);
									}

								}}
							/>
						</div>
						<div className='mr-3'>
							<Label>Time</Label>
							<Input
								type='time'
								value={timeValue}
								// min={moment().format("YYYY-MM-DD")}
								disabled={!walletAddress}
								onChange={e => {
									setTimeValue(e.target.value);
									const time = e.target.value;
									if (dateRange) {
										let selectedDateTime = (moment(dateRange).set({ h: time.split(':')[0], m: time.split(':')[1] }).valueOf() - moment().valueOf()) / 1000;
										setDateValue(selectedDateTime);
										// moment(dateRange).set({ h: time.split(':')[0], m: time.split(':')[1] });
									}
									// else if ()
									// const timestamp = new Date(e?.target?.value).getTime();
									// const timePeriod = Math.floor((timestamp - Date.now()) / 1000);

									// if (timePeriod >= maxDate) {
									// 	setDateRangeValue(100);
									// } else {
									// 	const range = (timePeriod / maxDate) * 100;
									// 	setDateRangeValue(range);
									// }

								}}
							/>
						</div>
					</div>
					<Input
						className='mt-5'
						type='range'
						value={dateRangeValue}
						disabled={!walletAddress}
						onChange={e => {
							console.log("range", e.target.value);
							setDateRangeValue(parseFloat(e?.target?.value));
							const amount =
								(e?.target?.value / 100) * parseFloat(maxDate);

							const value = moment(new Date(Date.now() + amount * 1000)).format('YYYY-MM-DD');
							setDateRange(value);

							if (!timeValue) {
								console.log("amount", amount);
								setDateValue(amount);
							} else {
								console.log("value", value);
								let selectedDateTime = (moment(value).set({ h: timeValue.split(':')[0], m: timeValue.split(':')[1] }).valueOf() - moment().valueOf()) / 1000;
								setDateValue(selectedDateTime);
								console.log("date time", selectedDateTime, "\n amount", amount);
								console.log("previous amount", moment(new Date(Date.now() + amount * 1000)).toISOString(), "\n new one ", moment(new Date(Date.now() + selectedDateTime * 1000)).toISOString())

							}
						}}
					/>
					<div className='d-flex mt-2'>
						<span className='ml-auto fs--36 fw--700 lplock__percentage'>
							{dateRangeValue?.toFixed(2) || 0}%
						</span>
					</div>
					<div className='d-block d-md-flex mt-5 align-items-center justify-content-between px-5'>
						<Badge
							className='lplock__badge mr-2 mt-2'
							onClick={() => {
								if (walletAddress) {
									setDateRangeValue(5);
									const amount =
										(5 / 100) * parseFloat(maxDate);
									setDateValue(amount);
									setDateRange(new Date(Date.now() + amount * 1000));
								}
							}}
						>
							3 Months{' '}
						</Badge>
						<Badge
							className='lplock__badge mr-2 mt-2'
							onClick={() => {
								if (walletAddress) {
									setDateRangeValue(10);
									const amount =
										(10 / 100) * parseFloat(maxDate);
									setDateValue(amount);
									setDateRange(new Date(Date.now() + amount * 1000));
								}
							}}
						>
							6 Months{' '}
						</Badge>
						<Badge
							className='lplock__badge mr-2 mt-2'
							onClick={() => {
								if (walletAddress) {
									setDateRangeValue(20);
									const amount =
										(20 / 100) * parseFloat(maxDate);
									setDateValue(amount);
									setDateRange(new Date(Date.now() + amount * 1000));
								}
							}}
						>
							1 Year{' '}
						</Badge>
						<Badge
							className='lplock__badge mr-2 mt-2'
							onClick={() => {
								if (walletAddress) {
									setDateRangeValue(100);
									const amount =
										(100 / 100) * parseFloat(maxDate);
									setDateValue(amount);
									setDateRange(new Date(Date.now() + amount * 1000));
								}
							}}
						>
							5 Year{' '}
						</Badge>
					</div>
				</div>
			</div>
			<div className='d-flex mt-3'>
				<Input
					type='checkbox'
					checked={!isByToken}
					name='fee-checkbox'
					onClick={() => setIsByToken(false)}
				/>
				<span
					className='fs--24 fw--700 lplock__flat-feet'
					onClick={() => setIsByToken(false)}
				>
					Flat Feet: 200 WDoge
				</span>
			</div>
			<div className='d-flex mt-3'>
				<Input
					type='checkbox'
					checked={isByToken}
					name='fee-checkbox'
					onClick={() => setIsByToken(true)}
				/>
				<span
					className='fs--24 fw--700 lplock__flat-feet'
					onClick={() => setIsByToken(true)}
				>
					Flat Feet: 0.3%
				</span>
			</div>
			<div className='mt-4'>
				<Button
					className='mr-3 btn btn-primary'
					onClick={approveAmountHandler}
					disabled={!walletAddress || approveLoader}
				>
					{approveLoader && <Spinner size='sm' />}
					Approve
				</Button>
				<Button
					className='btn btn-primary'
					disabled={!walletAddress || lockLoader}
					onClick={lockAmountHandler}
				>
					{lockLoader && <Spinner size='sm' />}
					Lock
				</Button>
			</div>
		</Container>
	);
}

export default LpLock;
