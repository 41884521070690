import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { configEnv } from './configEnv';
import { store as web3Store } from '../store/store';
import {
	setMyLocks,
	setTokenBalance,
	setWalletAddress,
} from '../store/walletSlice';
import { TOKEN_ABI, TOKEN_LOCKER_ABI } from './ABIs';
import { toast } from 'react-toastify';
export let web3 = new Web3(configEnv.DOGE_RPC);
export const connectWallet = async () => {
	try {
		const state = web3Store.getState();
		const address = state?.web3Reducer?.userAddress;
		if (!address) {
			console.log('Connecting to wallet');
			// /*
			const providerOptions = {
				walletconnect: {
					package: WalletConnectProvider,
					options: {
						bridge: 'https://bridge.walletconnect.org',
						chainId: configEnv.DOGE_CHAINID, //137 for mainnet
						// network: configEnv.DOGE_NETWORK_ID, //matic for mainnet
						rpc: {
							43113: configEnv.DOGE_RPC,
							// 43114: configEnv.DOGE_RPC,
						},
					},
				},
			};

			console.log('Provider is', providerOptions);

			const web3Modal = new Web3Modal({
				// network: configEnv.DOGE_NETWORK_ID,
				cacheProvider: false, // optional
				providerOptions, // required
				disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
			});

			console.log('Web3Modal instance is', web3Modal);
			const provider = await web3Modal.connect();

			provider.on('accountsChanged', async accounts => {
				console.log('Accounts', accounts);
				web3Store.dispatch(setWalletAddress(accounts[0]));
				getMyLocked(accounts[0]);
				// await getUserData();
				// await getUserDeposits();
			});

			web3 = new Web3(provider);

			console.log('Web3 instance is', web3);

			const chainid = await web3.eth.getChainId();

			console.log(chainid);
			if (chainid !== configEnv.DOGE_CHAINID) {
				// notification("error", `Please connect to ${configEnv.DOGE_NETWORK_NAME}`);
				toast.warn(`Please connect to ${configEnv.DOGE_NETWORK_NAME}`);
				// alert(`Please connect to ${configEnv.DOGE_NETWORK_NAME}`);
				return;
			}
			const accounts = await web3.eth.getAccounts();
			console.log('Acount is', accounts[0]);
			web3Store.dispatch(setWalletAddress(accounts[0]));
			getMyLocked(accounts[0]);
			// await getUserData();
			// await getUserDeposits();
		} else {
			console.log('Already connected');
		}
	} catch (err) {
		console.log(err);
		toast.warn(err?.message);
		// alert(err.message);
	}
};

export const getBalanceOfToken = async tokenAddress => {
	try {
		const state = web3Store.getState();
		const address = state?.wallet?.walletAddress;
		if (!address) {
			throw new Error('Please connect your wallet first');
		}
		const tokenContract = new web3.eth.Contract(TOKEN_ABI, tokenAddress);

		const tokenBalanceInWei = await tokenContract.methods
			.balanceOf(address)
			.call();
		const tokenBalanceInEth = web3.utils.fromWei(
			tokenBalanceInWei,
			'ether'
		);
		web3Store?.dispatch(
			setTokenBalance({
				tokenBalanceInWei,
				tokenBalanceInEth,
			})
		);
	} catch (err) {
		console.log(err);
		// alert(err?.message);
		toast.warn(err?.message);
		throw new Error(err?.message);
	}
};

export const approveToken = async (tokenAddress, amount, approvalAddress) => {
	try {
		const state = web3Store.getState();
		const address = state?.wallet?.walletAddress;
		if (!address) {
			throw new Error('Please connect your wallet first');
		}
		if (amount > 0) {
			const amountInWei = web3.utils.toWei(amount?.toString(), 'ether');

			const tokenContract = new web3.eth.Contract(
				TOKEN_ABI,
				tokenAddress
			);

			const res = await tokenContract.methods
				.approve(approvalAddress, amountInWei)
				.send({ from: address });
			console.log(res);

			// alert(`${amount} token approved`);
			toast.success(`${amount} token approved`);
			return res;
		} else {
			// alert('Invalid amount');
			toast.warn('Invalid amount');
		}
	} catch (err) {
		console.log(err);
		// alert(err?.message);
		toast.warn(err?.message);
		throw new Error(err?.message);
	}
};

export const lockTokenWithDoge = async (tokenAddress, amount, releaseTime) => {
	try {
		const state = web3Store.getState();
		const address = state?.wallet?.walletAddress;
		if (!address) {
			throw new Error('Please connect your wallet first');
		}
		if ((amount > 0, releaseTime > 0)) {
			const amountInWei = web3.utils.toWei(amount?.toString(), 'ether');

			const tokenLockerContract = new web3.eth.Contract(
				TOKEN_LOCKER_ABI,
				configEnv?.CONTRACT_ADDRESS
			);
			const fee = web3.utils.toWei('200', 'ether');
			const res = await tokenLockerContract.methods
				.addLockWithDoge(tokenAddress, amountInWei, releaseTime)
				.send({ from: address, value: fee });
			console.log(res);

			// alert(`${amount} token locked`);
			toast.success(`${amount} token locked`);

			return res;
		} else {
			// alert('invalid options');
			toast.warn('invalid options');
		}
	} catch (err) {
		console.log(err);
		// alert(err?.message);
		toast.warn(err?.message);
		throw new Error(err?.message);
	}
};

export const lockTokenWithToken = async (tokenAddress, amount, releaseTime) => {
	try {
		const state = web3Store.getState();
		const address = state?.wallet?.walletAddress;
		if (!address) {
			throw new Error('Please connect your wallet first');
		}
		releaseTime = Math.floor(releaseTime)
		const amountInWei = web3.utils.toWei(amount, 'ether');

		const tokenLockerContract = new web3.eth.Contract(
			TOKEN_LOCKER_ABI,
			configEnv?.CONTRACT_ADDRESS
		);
		debugger
		const res = await tokenLockerContract.methods
			.addLockWithToken(tokenAddress, amountInWei, releaseTime)
			.send({ from: address });
		console.log(res);

		// alert(`${amount} token locked`);
		await getMyLocked(address)
		toast.success(`${amount} token locked`);

		return res;
	} catch (err) {
		console.log(err);
		// alert(err?.message);
		toast.warn(err?.message);
		throw new Error(err?.message);
	}
};

export const getMyLocked = async address => {
	try {
		const tokenLockerContract = new web3.eth.Contract(
			TOKEN_LOCKER_ABI,
			configEnv?.CONTRACT_ADDRESS
		);

		const userLocks = await tokenLockerContract.methods
			.getUserLocks(address)
			.call();

		let locks = [];

		for await (let lockItem of userLocks) {
			let data = await tokenLockerContract.methods
				._lockersData(lockItem)
				.call();

			data = JSON.parse(JSON.stringify(data));
			data['amountInEth'] = web3.utils.fromWei(data?.amount,'ether');
			locks.push(data);
		}
		locks = JSON.parse(JSON.stringify(locks));
		
		web3Store.dispatch(setMyLocks(locks));
	} catch (err) {
		console.log(err);
		throw new Error(err?.message);
	}
};

export const withdrawLock = async (id)=>{
	try{
		const state = web3Store.getState();
		const address = state?.wallet?.walletAddress;
		if (!address) {
			throw new Error('Please connect your wallet first');
		}

		const tokenLockerContract = new web3.eth.Contract(
			TOKEN_LOCKER_ABI,
			configEnv?.CONTRACT_ADDRESS
		);
		
		const res = await tokenLockerContract.methods
			.releaseLock(parseInt(id))
			.send({ from: address });
		console.log(res);

		// alert(`${amount} token locked`);
		await getMyLocked(address)
		toast.success(`Lock# ${id} token released`);
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message);
	}
}

export const extendLock = async (id,newReleaseTime)=>{
	try{
		const state = web3Store.getState();
		const address = state?.wallet?.walletAddress;
		if (!address) {
			throw new Error('Please connect your wallet first');
		}

		const tokenLockerContract = new web3.eth.Contract(
			TOKEN_LOCKER_ABI,
			configEnv?.CONTRACT_ADDRESS
		);
		
		const res = await tokenLockerContract.methods
			.extendLock(parseInt(id),newReleaseTime)
			.send({ from: address });
		console.log(res);

		// alert(`${amount} token locked`);
		await getMyLocked(address)
		toast.success(`Lock# ${id} time extended`);
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message);
	}
}