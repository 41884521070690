import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    walletAddress:'',
    tokenBalanceInWei:'0',
    tokenBalanceInEth:'0',
    myLocks:[],
}

export const WalletSlice = createSlice({
    name:'wallet',
    initialState,
    reducers:{
        setWalletAddress(state,action){
            state.walletAddress = action?.payload;
        },
        setTokenBalance(state,action){
            const {tokenBalanceInEth,tokenBalanceInWei} = action.payload;
            state.tokenBalanceInWei = tokenBalanceInWei;
            state.tokenBalanceInEth = tokenBalanceInEth;
        },
        setMyLocks(state,action){
            state.myLocks = action.payload;
        }
    },
});

export const { 
    setWalletAddress,
    setTokenBalance,
    setMyLocks,
} = WalletSlice.actions

export default WalletSlice.reducer