import React, { useState } from "react";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Badge, Container, Input, Label, Spinner } from "reactstrap";
import Reward from "../../assets/img/reward.png";
import { extendLock, withdrawLock } from "../../utils/web3-helpers";
import moment from "moment";

function MyLock() {
  const { walletAddress, myLocks } = useSelector((state) => state?.wallet);

  const [withdrawLockLoader, setWithdrawLockLoader] = useState(false);
  const [extendLockLoader, setExtendLockLoader] = useState(false);
  const [extendDateRange, setExtendDateRange] = useState([{
    dateRange: '',
    dateValue: 0,
    timeValue: '',
    index: ''
  }])
  const [dateRange, setDateRange] = useState();
  const [dateValue, setDateValue] = useState(0);
  const [timeValue, setTimeValue] = useState('');

  const withdrawLockHandler = async (id) => {
    try {
      setWithdrawLockLoader(true);
      const res = await withdrawLock(id);
      setWithdrawLockLoader(false);
    } catch (err) {
      console.log(err);
      setWithdrawLockLoader(false);
      toast.warn(err?.message);
      throw new Error(err?.message);
    }
  };

  const extendLockHandler = async (id, index) => {
    try {
		setExtendLockLoader(true)
      console.log({ dateValue });
      let selectedDateValue = extendDateRange[index]?.dateValue;
      if (!selectedDateValue) {
        toast.warn("Please select extended date");
      } else {
        const res = await extendLock(id, selectedDateValue);
        setExtendLockLoader(false);
        setDateValue('');
        setDateValue(0);
        setTimeValue('');
        let temp = JSON.parse(JSON.stringify(extendDateRange));
        temp.splice(index, 1);
        setExtendDateRange([{
          dateRange: '',
          dateValue: 0,
          timeValue: '',
          index: ''
        }]);
      }
    } catch (err) {
      console.log(err);
	  setExtendLockLoader(false)
      throw new Error(err?.message);
    }
  };

  return (
    <Container className="mylock mb-5 text-left liquidity__text">
      {!walletAddress && <span>Please connect wallet to see locks.</span>}
      {myLocks?.length == 0 ? (
        <span>No lock found</span>
      ) : (
        <div className="all-lock">
            {myLocks?.map((item, idx) => {
            return (
              <div className="lock-item">
                <div
                  key={idx}
                  className="d-block d-lg-flex align-items-center all-lock__reward mt-4 pb-3 "
                >
                  <img
                    src={Reward}
                    alt="reward"
                    className="mr-3 d-none d-lg-block"
                  />
                  <div>
                    <h2 className="fs--28 fw--500 all-lock__reward-title">
                      {item?.tokenAddress}
                    </h2>
                    <span className="fs--20 fw--400 all-lock__reward-subtitle">
                      <strong>Locker Address:</strong> {item?.lockerAddress}
                    </span>
                  </div>
                  <div className="ml-auto mr-2 fs--16 fw--400 all-lock__reward-subtitle">
                    <strong>Amount: </strong>
                    {item?.amountInEth}
                    <br />
                    {item?.isPaid ? (
                      <Badge color="success">PAID</Badge>
                    ) : (
                      <Badge color="danger">UNPAID</Badge>
                    )}
                    <br />
					{!item?.isPaid ?
                    <Countdown
                      className="timer"
                      date={new Date(parseInt(item?.lockEndTime) * 1000)}
                    >
                      <button
                        disabled={!walletAddress  || withdrawLockLoader}
                        onClick={() => {
                          withdrawLockHandler(item?.id);
                        }}
                        className="mr-3 btn btn-primary"
                      >
						{withdrawLockLoader && <Spinner size='sm' />}
						Withdraw
                      </button>
                    </Countdown>:
					<Countdown></Countdown>
		  }
                  </div>
                </div>
                {!item?.isPaid && (
                  <div className="d-flex justify-content-between mr-4">
                    <div className="mr-3 ml-3 d-flex">
                      <Label> Extend Date</Label>
                      <Input
                        type="date"
                        value={extendDateRange[idx]?.dateRange || ''}
                        min={moment(
                          new Date(parseInt(item?.lockEndTime) * 1000)
                        ).format("YYYY-MM-DD")}
                        disabled={!walletAddress}
                        onChange={(e) => {
                          setDateRange(e.target.value);
                          const inputDate = e.target.value;
                          if (timeValue) {
                            let selectedDateTime = (moment(inputDate).set({ h: timeValue.split(':')[0], m: timeValue.split(':')[1] }).valueOf()) / 1000;
                            setDateValue(selectedDateTime);
                          } else {
                            let selectedDateTime = (moment(inputDate).valueOf()) / 1000;
                            setDateValue(selectedDateTime);
                          }
                          //For Array Logic 
                          const findIndex = extendDateRange.findIndex(el => el.index == idx);
                          if (findIndex != -1) {
                            let temp = JSON.parse(JSON.stringify(extendDateRange));
                            temp[findIndex].dateRange = e.target.value;
                            if (temp[findIndex].timeValue) {
                              temp[findIndex].dateValue = (moment(inputDate).set({ h: temp[findIndex].timeValue.split(':')[0], m: temp[findIndex].timeValue.split(':')[1] }).valueOf()) / 1000;
                            }
                            setExtendDateRange(temp);
                          } else {
                            setExtendDateRange([...extendDateRange, { index: idx, dateRange: inputDate, timeValue: '', dateValue: (moment(inputDate).valueOf()) / 1000 }]);
                          }

                        }}
                      />
                    </div>
                    <div className="mr-3 ml-3 d-flex">
                      <Label> Extend Time</Label>
                      <Input
                        type="time"
                        value={extendDateRange[idx]?.timeValue || ''}
                        disabled={!walletAddress}
                        onChange={(e) => {
                          setTimeValue(e.target.value);
                          const time = e.target.value;

                          if (dateRange) {
                            let selectedDateTime = (moment(dateRange).set({ h: time.split(':')[0], m: time.split(':')[1] }).valueOf()) / 1000;
                            setDateValue(selectedDateTime);
                          }

                          //For Array logic
                          const findIndex = extendDateRange.findIndex(el => el.index == idx);
                          if (findIndex != -1) {
                            let temp = JSON.parse(JSON.stringify(extendDateRange));
                            temp[findIndex].timeValue = e.target.value;
                            if (temp[findIndex].dateRange) {
                              temp[findIndex].dateValue = (moment(temp[findIndex].dateRange).set({ h: time.split(':')[0], m: time.split(':')[1] }).valueOf()) / 1000;
                            }
                            setExtendDateRange(temp);
                          } else {
                            setExtendDateRange([...extendDateRange, { index: idx, dateRange: '', timeValue: e.target.value, dateValue: 0 }]);
                          }

                        }}
                      />
                    </div>
                    <button
                      className="btn btn-alt p-2 fs--24 mb-2"
                      onClick={() => extendLockHandler(item?.id, idx)}
                      disabled={!walletAddress || extendLockLoader || !extendDateRange.some(el => el.index == idx) || extendDateRange.some(el => el.index == idx && el.dateValue == 0)}
                    >
						{extendLockLoader && <Spinner size='sm' />}
                      	Extend Lock
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </Container>
  );
}

export default MyLock;
